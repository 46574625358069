import * as React from "react"
import { Link } from "gatsby"
import { reviews } from "../data/reviews"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const About = () => (
  <Layout>
    <Seo title="Reviews" />
    <h1>Reviews</h1>
    <p>
      The following reviews are taken from my{" "}
      <Link to="https://www.facebook.com/plutoastrologen">
        Swedish astrology Facebook page
      </Link>
      , translations mine.
    </p>

    {reviews.map(person => (
      <section style={{ marginBottom: "2.5rem" }} key={Math.random()}>
        {person.paragraphs.map(p => (
          <p style={{ fontStyle: "italic" }} key={Math.random()}>{p}</p>
        ))}
        <p>{person.name}</p>
      </section>
    ))}
  </Layout>
)

export default About
