export const reviews = [
    {
        name: "Anna Burman",
        paragraphs: ["I had such a lovely and rewarding session with Kim. Things fell into place and I gained a greater understanding of my life's journey. Kim is very soft, warm and humble as a person and keeps a very open space. He takes the time to really understand your situation and has a lot of knowledge in his field. I'm so thankful! ❤️"]
    },
    {
        name: "Malin Mellström",
        paragraphs: ["The things that surfaced during the consultation with Kim were so exact and gave me so many new insights and a steadier direction on my path. Thank you, what an incredibly strong session this was! Things fell in place so beautifully – I understand much more clearly where I come from and where I’m headed, where I should put my focus and what I should leave behind.", "Kim has a very gentle approach, he meets up with humility and reflection, asks clarifying questions and together we work for deeper clarity and understanding of my development as a human being and as a soul. I can warmly recommend him as astrologer and guide!", "From the depth of my heart, thank you!"]
    },
    {
        name: "Petra Broström",
        paragraphs: ["What an incredibly educative and astonishing 90 minutes this was!! Kim interpreted and described in depth how my life has been. With amazing intuitive clarity, he answered questions I’ve had and sought information about for many years, the how, what and why! I am AMAZED!", "I had to stop myself several times and remind myself that Kim was still (at the time) a student and not finished in his astrological education, even though he is strangely accurate and empathic!!", "I WARMLY recommend Kim – for the seeking soul!"]
    },
    {
        name: "Dick Tärnhammar",
        paragraphs: ["Thank you Kim for your guidance around my horoscope. Your strong ability to feel your way and gently communicate your interpretations is wonderful. It was surprising and very touching when your interpretation approached my deepest yearning. It was actually a new level and depth which I had not perceived before. I am no fan of astrology – but your way of communicating and conversing around it is impressive. I am very grateful for that. It opens up new possibilities and thoughts."]
    },
    {
        name: "Anna Andersson",
        paragraphs: ["Compared to reading one’s personal horoscope (where paradoxical traits are sometimes listed), this consultation was more rewarding with a discussion around specific issues. It was structured like a conversation, not at all like a lecture. At the same time very informative regarding the influence of various nodes and houses. I learnt a lot and received a generous amount of time for reflection and follow-up questions. Very accommodating and professional approach"]
    },
    {
        name: "Irene Rimay Ahlberg",
        paragraphs: ["Kim is a very warm, clearsighted and humble person and I am so grateful for the inspiring, insightful, developing and eye-opening consultation. I gained “new” astrological and personal perspectives on myself and my life, connections were made, I gained understanding and insights in a new way. I was also provoked in a healthy way, which is important to be able to change and continue on an authentic and true path. Besides, I feel such a deep JOY. Thank you Kim! And I recommend him and evolutionary astrology warmly!"]
    },
]
